// TitleBar.js

import React, { useState, useEffect } from "react";

import "./TitleBar.css";
import logo from "./logo192.jpg";
import LoginForm from "./LoginForm"; // 新增导入登录表单组件

const TitleBar = ({ userId, setUserId }) => {
  const [showLogin, setShowLogin] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.classList.contains("user-id")) {
        setShowLogin(true);
      } else if (showLogin && !event.target.closest(".login-form")) {
        setShowLogin(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [showLogin]);
  return (
    <div className="title-bar">
      <a href="https://trustavo.com/" target="_blank">
        <img src={logo} alt="Logo" className="logo" />
      </a>
      <h1 className="title-text">Trustavo AI News</h1> {/* 更新标题 */}
      <div
        onClick={() => {
          setShowLogin(true);
        }}
        className="user-id"
      >
        {userId}
      </div>
      {console.log("Rendering login form, showLogin:", showLogin)}
      {showLogin && <LoginForm setUserId={setUserId} setShowLogin={setShowLogin} />}
    </div>
  );
};
export default TitleBar;
