import React, { useState, useEffect, useCallback, useRef } from "react";
import NewsItem from "./NewsItem";
import LanguageCountryPicker from "./LanguageCountryPicker";
import config from "../news-setting.json";
import _ from "lodash";

function NewsList() {
  // Extract valid languages from the config
  const validLanguages = config.results.map((item) => item.language);

  const getValidatedLanguage = (language) => {
    const extractedLangCode = language.split("-")[0];
    return validLanguages.includes(extractedLangCode) ? extractedLangCode : null;
  };

  // Determine initial language
  const initialLanguage = localStorage.getItem("selectedLanguage") || getValidatedLanguage(navigator.language || navigator.userLanguage) || "en";

  // Determine initial country
  const initialCountry = localStorage.getItem("selectedCountry") || "global";

  // useState hooks for selectedLanguage and selectedCountry
  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage);
  const [selectedCountry, setSelectedCountry] = useState(initialCountry);

  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMoreNews, setHasMoreNews] = useState(true); // State to track if more news is available
  const loader = useRef(null);

  const CACHE_EXPIRATION_MINUTES = 10; // 例如，设置缓存过期时间为60分钟

  const handleAddToSlack = () => {
    // 你可以在这里添加额外的逻辑，如果需要的话
    window.open(
      "https://slack.com/oauth/v2/authorize?client_id=5277445063169.6906705078548&scope=chat:write,commands,im:history,incoming-webhook&user_scope=chat:write",
      "_blank"
    );
  };

  const fetchNews = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const cacheKey = `news-${selectedLanguage}-${selectedCountry}-${page}`;
      let fetchedNews;

      const cachedData = localStorage.getItem(cacheKey);
      const cacheTime = localStorage.getItem(`${cacheKey}-time`);

      // Check if cache is expired
      const isCacheExpired = () => {
        if (!cacheTime) return true;
        const now = new Date().getTime();
        const cachedTime = new Date(parseInt(cacheTime)).getTime();
        return now - cachedTime > CACHE_EXPIRATION_MINUTES * 60 * 1000;
      };

      if (cachedData && !isCacheExpired()) {
        // Use cached data if available and not expired
        fetchedNews = JSON.parse(cachedData);
      } else {
        // Fetch news from API
        const apiUrl = `${process.env.REACT_APP_API_URL}/?language=${selectedLanguage}&country=${selectedCountry}&page=${page}`;
        const response = await fetch(apiUrl);
        if (!response.ok) throw new Error("Network response was not ok.");
        const data = await response.json();
        fetchedNews = data.results;

        // Store new data in cache
        localStorage.setItem(cacheKey, JSON.stringify(fetchedNews));
        localStorage.setItem(`${cacheKey}-time`, new Date().getTime().toString());
      }

      // Combine existing news and new fetched news
      const combinedNews = [...news, ...fetchedNews];
      // Remove duplicates based on title
      const uniqueNews = Array.from(new Set(combinedNews.map((item) => item.title))).map((title) =>
        combinedNews.find((item) => item.title === title)
      );

      setNews(uniqueNews);

      // Update the state to reflect whether more news is available
      if (fetchedNews.length === 0) {
        setHasMoreNews(false);
      }
    } catch (error) {
      console.error("Fetch news error:", error);
      setError("Failed to load news");
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetchNews = useCallback(_.debounce(fetchNews, 500), [selectedLanguage, selectedCountry, page, news]);

  useEffect(() => {
    debouncedFetchNews();
    return () => {
      debouncedFetchNews.cancel();
    };
  }, [debouncedFetchNews]);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && !isLoading && hasMoreNews) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    [isLoading, hasMoreNews]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "600px",
      threshold: 0.2,
    });
    const currentLoader = loader.current;
    if (currentLoader) observer.observe(currentLoader);
    return () => {
      if (currentLoader) observer.unobserve(currentLoader);
    };
  }, [handleObserver]);

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    localStorage.setItem("selectedLanguage", newLanguage);
    setPage(1);
    setNews([]);
    setSelectedCountry("global");
    localStorage.setItem("selectedCountry", "global");
    setHasMoreNews(true); // Reset hasMoreNews when language changes
  };

  const handleCountryChange = (event) => {
    const newCountry = event.target.value;
    setSelectedCountry(newCountry);
    localStorage.setItem("selectedCountry", newCountry);
    setPage(1);
    setNews([]);
    setHasMoreNews(true); // Reset hasMoreNews when country changes
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="row">
      <button onClick={handleAddToSlack} className="add-to-slack-btn">
        Add to Slack
      </button>
      <div className="col-12">
        <LanguageCountryPicker
          selectedLanguage={selectedLanguage}
          selectedCountry={selectedCountry}
          onLanguageChange={handleLanguageChange}
          onCountryChange={handleCountryChange}
          config={config}
        />
      </div>
      {news.map((newsItem) => (
        <div key={newsItem.id} className="col-sm-12 col-md-6 col-lg-4">
          <NewsItem item={newsItem} />
        </div>
      ))}
      <div ref={loader}>{isLoading && <div>Loading more news...</div>}</div>
    </div>
  );
}

export default NewsList;
