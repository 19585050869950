// LoginForm.js
import React, { useState } from "react";
import "./LoginForm.css";

const LoginForm = ({ setUserId, setShowLogin }) => {
  const [inputUserId, setInputUserId] = useState("");

  const handleLogin = () => {
    if (inputUserId.trim() !== "") {
      setUserId(inputUserId.trim());
      setShowLogin(false);
    }
  };

  return (
    <div className="login-form">
      <input type="text" placeholder="Enter your user ID" value={inputUserId} onChange={(e) => setInputUserId(e.target.value)} />
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default LoginForm;
