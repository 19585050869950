import React, { useMemo } from "react";

function LanguageCountryPicker({ selectedLanguage, selectedCountry, onLanguageChange, onCountryChange, config }) {
  const uniqueLanguages = useMemo(() => {
    return config && config.results ? Array.from(new Set(config.results.map((item) => item.language))) : [];
  }, [config]);

  const getCountryOptions = useMemo(() => {
    if (config && config.results) {
      return ["global", ...new Set(config.results.filter((lc) => lc.language === selectedLanguage).map((lc) => lc.country))];
    }
    return ["global"];
  }, [selectedLanguage, config]);

  return (
    <div>
      <select className="custom-select custom-select-enhanced" value={selectedLanguage} onChange={onLanguageChange} aria-label="Select Language">
        {uniqueLanguages.map((language) => (
          <option key={language} value={language}>
            {language.toUpperCase()}
          </option>
        ))}
      </select>
      <select className="custom-select custom-select-enhanced" value={selectedCountry} onChange={onCountryChange} aria-label="Select Country">
        {getCountryOptions.map((country) => (
          <option key={`${selectedLanguage}-${country}`} value={country}>
            {country.toUpperCase()}
          </option>
        ))}
      </select>
    </div>
  );
}

export default React.memo(LanguageCountryPicker);
