import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import NewsList from "./components/NewsList";
import TitleBar from "./components/TitleBar";
import LoginForm from "./components/LoginForm";
import "./App.css";

function App() {
  const [userId, setUserId] = React.useState("");
  return (
    <div>
      <TitleBar userId={userId} setUserId={setUserId} />
      <div className="container">
        <NewsList />
      </div>
    </div>
  );
}

export default App;
